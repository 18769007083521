body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Default style */
.fixed-size-img {
  width: 100%;
  height: 400px; /* Adjust height as needed */
  object-fit: cover;
}

#gallery-btn{
  width: 258px;
}

/*Nav bar image*/

.defaultImage{
  position: absolute;
  z-index: 5;
  margin-top:40px;
  padding-left: 0;
}

.defaultTitle{
  color: black;
  padding-left:130px;
  font-size: 30px;
  padding-top:20px
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .fixed-size-img {
    height: 247px;
  }
  #gallery-btn {
    width: 270px !important;
    height: 48px;
  }
  .gallery-btn-div{
    margin-top:0px ;
  }
  .defaultImage{
    top: -48px;
    left: -22px;
    width: 82px;
  }
  .defaultTitle{
    padding-left: 56px;
    font-size: 21px;
    padding-top: 15px;
  }

  .udawalawaPic01{
    width: 337px;
  }

  .myGridContainer {
    flex-direction: column-reverse; /* Reverse order on xs screens */
    gap:10px
  }
  .row-sm{
    gap:10px

  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 764px) {
  .myGridContainer {
    flex-direction: column-reverse; /* Reverse order on xs screens */
  }
  .fixed-size-img {
    height: 270px;
  }
  #gallery-btn {
    width: 255px !important;
  }
  .defaultTitle{
    padding-left: 94px;
    font-size: 24px;
    padding-top: 10px;
  }
  .defaultImage{
    width: 103px;
  }
  .udawalawaPic01{
    width: 300px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 765px) and (max-width: 991px) {

  .fixed-size-img {
    height: 300px;
  }
  #gallery-btn {
    width: 300px !important;
  }
  .defaultTitle{
    padding-left: 94px;
    font-size: 24px;
    padding-top: 10px;
  }
  .defaultImage{
    width: 103px;
  }
  .udawalawaPic01B{
    width: 300px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fixed-size-img {
    height: 325px;
  }
  .defaultImage{
    margin-left: -36px;
    padding-top: 14px;
    max-width: 186px;
  }
  .udawalawaPic01C{
    width: 300px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .fixed-size-img {
    height: 350px;
  }
  #gallery-btn {
    width: 255px !important;
  }
  .defaultImage{
    max-height: 111px;
  }
  .defaultTitle{
    padding-left: 102px;
  }
  .udawalawaPic01D{
    width: 300px;
  }
}




.animal-item .position-relative {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.animal-item:hover .position-relative {
  transform: scale(1.05);
}

.animal-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  text-align: center;
  transition: background 0.3s ease;
}

.animal-item:hover .animal-text {
  background: rgba(0, 0, 0, 0.7);
}

.text-primary {
  color: #007bff;
}

.btn-primary {

  background-color: #007bff;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/*Udawalawa & */
.btn-primary {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #4CAF50; /* Modern green color */
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer;
  margin: 10px;
}

.btn-primary:hover {
  background-color: #45a049; /* Darker green on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.btn-primary:active {
  background-color: #3e8e41; /* Even darker green on click */
  box-shadow: 0 2px 4px rgba(231, 34, 34, 0.1); /* Weaker shadow on click */
  transform: translateY(2px); /* Slight movement on click */
}

.rounded-btn {
  border-radius: 30px; /* Adjust the value as needed for more or less rounding */
}


/*package CSS*/
.custom-modal .modal-content {
  border: 2px solid green;
  border-radius: 10px;
}

.custom-modal .modal-header {
  background-color: #f0f8ff;
  border-bottom: 0;
}

.custom-modal .modal-footer {
  background-color: #f0f8ff;
  border-top: 0;
}

.custom-modal .modal-title {
  font-weight: bold;
}

.custom-modal .btn-outline-secondary {
  border-color: green;
  color: green;
}

.custom-modal .btn-outline-secondary:hover {
  background-color: green;
  color: white;
}


/*about us cover css*/
.header-bg-about {
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/aboutUs.jpg) center center no-repeat;
  background-size: cover;
}

.header-bg-Udawalawa {
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/ele1.jpg) center center no-repeat;
  background-size: cover;
}

.header-bg-Yala {
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/yala1.jpg) center center no-repeat;
  background-size: cover;
}

/*.header_bg-udawalawa1{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../../Safari/public/img/ubawalawa1.jpg) center center no-repeat;
  background-size: cover;
}*/

.header_bg-udawalawa2{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/udawalawa22.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-udawalawa3{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/ele1.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-yala1{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/yala1.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-yala2{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/play6.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-yala3{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/yala33.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-service{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/Yala2.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-homeMain{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/le.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-footer{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/Mugger-crocodile-VW.jpg) center center no-repeat;
  background-size: cover;
}

/*.header_bg-visitingHour {
  background-color: rgba(0, 0, 0, 0.7); !* Adjust the color and transparency as needed *!
}*/


.header_bg-contact{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/yalaAnimal.png) center center no-repeat;
  background-size: cover;
}

.header_bg-AboutUdaYala{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/safari5.png) center center no-repeat;
  background-size: cover;
}

.header_bg-OurAnnimals{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/yala33.jpg) center center no-repeat;
  background-size: cover;
}

.header_bg-VisitingHourBg{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/udawalawaAnimal5.png) center center no-repeat;
  background-size: cover;
}


.header_bg-TestimonialBg{
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../public/img/play5.jpg) center center no-repeat;
  background-size: cover;
}


/*Scroll bar*/
.card-body::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
  height: 0; /* Remove scrollbar height */
}

.card-body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.no-bullets {
  list-style-type: none;
  padding-left: 0;
}

.card {
  background-color: #fff; /* Set the background to white */
  border: 1px solid #ddd; /* Add a light border */
  color: #333; /* Set text color to a darker shade */
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust shadow to be lighter */
}

/*hr*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the opacity as needed */
  z-index: 0;
}

/*static galary*/
.gallery-btn-div {
  padding: 0; /* Remove padding from columns */
  display: flex;
  justify-content: center; /* Center the buttons within each column */
  align-items: center;
}

#gallery-btn {
  margin: 0; /* Remove margin from buttons */
  width: auto; /* Ensure buttons only take up necessary width */
}

.row.g-0 {
  gap: 0; /* Ensure there's no gap between columns */
  display: flex; /* Make row a flex container */
  justify-content: center; /* Center contents horizontally */
}

/*Nav bar logo style*/



/*our annimals*/

.fixed-size {
  width: 100%;
  height: 400px; /* Set the desired fixed height */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.animal-item .position-relative {
  width: 100%;
  height: 300px; /* Set the same fixed height */
}

/* Media Query for responsive design */
@media (max-width: 576px) {
  .fixed-size {
    height: 400px; /* Adjust height for small screens */
  }
}

/* @media (max-width: 768px) {
  .text-center1 {
      text-align: center !important;
  }
} */

.reviews-section {
  padding: 20px;
}

.reviews-section .row > div {
  margin-bottom: 20px;
}

.review-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Limit to 10 lines */
  -webkit-box-orient: vertical;
}











